.fondo {
     background-color: #888888;
     height: 100%;
}

.margenPerfil {
     padding-top: 10px;
}

.margenEspecialPerfil {
     margin-left: -80px;
     margin-right: -80px;
}

.paddingEspecialPerfil {
     padding-left: 80px;
     padding-right: 80px;
}

.usuarioPerfil {
     background-color: rgb(0, 0, 0);
     border-radius: 10px;
     height: 100%;
     /* width: 239px; */
}

.encabezadoPerfil {
     background-color: #218E8E;
     border-radius: 10px;
     min-height: 43px;
     color: white;
     font-weight: bold;
     font-size: 24px;
     line-height: 36px;
     text-align: center;
}

.insigniasPerfil {
     background-color: white;
     border-bottom-right-radius: 0.25rem !important;
     border-bottom-left-radius: 0.25rem !important;
     border-top-right-radius: 0 !important;
     border-top-left-radius: 0 !important;
}

.insignia{
     object-fit: cover;
}

.demoExtraCalendario {
     background-color: white;
     border-radius: 10px;
     padding-bottom: 15px;
     height: 100%;
}

.demoExtras {
     background-color: white;
     border-radius: 10px;
     height: 100%;
     padding-bottom: 15px;
}

.demoExtras3 {
     background-color: white;
     border-bottom-right-radius: 0.25rem !important;
     border-bottom-left-radius: 0.25rem !important;
     border-top-right-radius: 0 !important;
     border-top-left-radius: 0 !important;
     height: 100%;
     min-height: 300px;
     padding-bottom: 15px;
}

.demoExtras4 {
     background-color: white;
     border-bottom-right-radius: 0.25rem !important;
     border-bottom-left-radius: 0.25rem !important;
     border-top-right-radius: 0 !important;
     border-top-left-radius: 0 !important;
     height: 100%;
     padding-bottom: 15px;
}

.divBlancoPerfil {
     background-color: white;
     border-bottom-right-radius: 0.25rem !important;
     border-bottom-left-radius: 0.25rem !important;
     border-top-right-radius: 0 !important;
     border-top-left-radius: 0 !important;
     min-height: 170px; /* RESPONSIVO */
     height: 100%; /* RESPONSIVO */
     padding-bottom: 5px;
     display: flex; /* RESPONSIVO */
     justify-content: center; /* RESPONSIVO */
}

.divVerdePerfil {
     background-color: #218E8E;
     border-bottom-right-radius: 0.25rem !important;
     border-bottom-left-radius: 0.25rem !important;
     border-top-right-radius: 0 !important;
     border-top-left-radius: 0 !important;
     height: 100%;
     padding-bottom: 15px;
}

.marginAbajoDemoExtras {
     margin-bottom: 10px;
}

.margenTopExtrasPerfil {
     margin-top: 10px;
}

.margenTopDemoExtrasPerfil {
     margin-top: 20px;
}

.demoExtras2 {
     background-color: white;
     border-radius: 10px;
     padding-bottom: 15px;
}

.franjaNegraPerfil {
     background-color: black;
     border-radius: 10px 10px 0px 0px;
     font-style: normal;
     font-weight: normal;
     font-size: 16px;
     line-height: 26px;
     color: white;
}

.linkRegresarPerfil {
     padding-top: 20px;
     color: white;
     font-style: normal;
     font-weight: bold;
     font-size: 12px;
     line-height: 14px;
}

.bienvenidaPerfil {
     color: white;
     font-style: normal;
     font-weight: normal;
     font-size: 20px;
     line-height: 24px;
     margin-top: 45px;
}

.fotoUsuarioPerfil {
     margin-top: 45px;
     object-fit: cover;
}

.nombreUsuarioPerfil {
     text-transform: uppercase;
     margin-top: 20px;
     color: white;
     font-style: normal;
     font-weight: bold;
     font-size: 20px;
     line-height: 26px;
}

.label1Perfil {
     font-style: normal;
     font-weight: normal;
     font-size: 14px;
     line-height: 20px;
     color: white;
}

.espacioPerfil {
     margin-top: 20px;
}

.linkMenuPerfil {
     font-style: normal;
     font-weight: normal;
     font-size: 14px;
     line-height: 20px;
     color: white;
}

.grupoLinksPerfil {
     margin-top: 15px;
}

.espacioInsigniaPerfil {
     padding: 10px 0 10px 0;
     width: 225px!important;
}

.imagenInsigniaPerfil {
     border-radius: 5px;
     border-style: solid;
     border-width: 2px;
     border-color: transparent;
     box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
}

.colorIconoBandera {
     color: #218E8E;
}

.textoDemoExtrasPerfil {
     color: #5F5F5F;
     font-style: normal;
     font-weight: normal;
     font-size: 16px;
     line-height: 16px;
}

.textoDatoDemoExtrasPerfil {
     font-style: normal;
     font-weight: bold;
     font-size: 18px;
     line-height: 28px;
     color: #218E8E;
}

.textoDatoBoldDemoExtrasPerfil {
     font-style: normal;
     font-weight: bolder;
     font-size: 18px;
     line-height: 28px;
     color: #218E8E;
}

.textoDatoBoldDemoExtrasPerfil {
     color: #5F5F5F;
     font-style: normal;
     font-weight: bolder;
     font-size: 16px;
     line-height: 16px;
}

.divValorDatoDemoExtrasPerfil {
     text-align: center;
     border-style: solid;
     border-width: 2px;
     border-color: #B7B7B7;
     width:44px!important;
     height: 30px!important;
}

.divValorDatoDemo2ExtrasPerfil {
     border-style: solid;
     border-width: 2px;
     border-color: #B7B7B7;
     margin-left: 10px;
}

.textoRojoDemoExtra {
     font-style: normal;
     font-weight: normal;
     font-size: 12px;
     line-height: 14px;
     color: #DF0024;
}

.textoRojoDemo2Extra {
     font-style: normal;
     font-weight: normal;
     font-size: 14px;
     line-height: 20px;
     color: #DF0024;
}

.textoRojoDemo3Extra {
     font-style: normal;
     font-weight: normal;
     font-size: 14px;
     line-height: 16px;
     color: #DF0024;
}

.checkMarkPerfil {
     -webkit-appearance: none;
     background-color: #E5E5E5 !important;
     width: 19px!important;
     height: 16px!important;
     border-style: none!important;
     margin-top: 5px;
	border-radius: 3px;
	display: inline-block;
	position: relative;
}

.checkMarkPerfil:checked {
     background-color: #218E8E !important;
     color: white!important;
}

.checkMarkPerfil:checked:after {
	content: '\2714';
	font-size: 14px;
	position: absolute;
	top: -1.5px;
	left: 3.5px;
	color: white;
}

.paddingExtrasProgresoPerfil {
     margin-top: 20px;
     margin-left: 5%;
     padding-bottom: 25px;
     border-radius: 5px;
     border-style: solid;
     border-width: 2px;
     border-color: transparent;
     box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
}

.textoRojoProgresoPerfil {
     color: #DF0024;
     font-style: normal;
     font-weight: normal;
     font-size: 18px;
     line-height: 20px;
}

.textoProgresoPerfil {
     color: #444444;
     font-style: normal;
     font-weight: normal;
     font-size: 18px;
     line-height: 20px;
}

.textoProgresoExtraPerfil {
     color: #5F5F5F;
     font-style: normal;
     font-weight: 400;
     font-size: 14px;
     line-height: 16px;
}

.textoChecksProgresoPerfil {
     color: #5F5F5F;
     font-style: normal;
     font-weight: normal;
     font-size: 16px;
     line-height: 18px;
}

.paddingContenidoGraficaProgresoPerfil {
     padding-top: 10px;
     /* margin-left: 10px; */
}

.ajusteChecksProgresoPerfil {
     padding-top:30px;
}

.ajusteGraficaProgresoPerfil {
     margin-top: 20px;
}

.paddingExtrasNivelPerfil {
     margin: 15px 15px 15px 15px;
}

.textoBaseNivelPerfil {
     font-style: normal;
     font-weight: normal;
     font-size: 14px;
     line-height: 16px;
     color: #888888;

}

.textoRojoNivelPerfil {
     font-style: normal;
     font-weight: normal;
     font-size: 14px;
     line-height: 16px;
     color: #DF0024;
}

.textoExtraNivelPerfil {
     font-style: normal;
     font-weight: normal;
     font-size: 12px;
     line-height: 20px;
     color: #5F5F5F;
}

.textoNivelPerfil {
     font-style: normal;
     font-weight: bold;
     font-size: 60px;
     line-height: 28px;
     color: #218E8E;
}

.divNivelPerfil {
     border-style: solid;
     width: 80px;
     height: 80px;
     border-radius: 100%;
     border-width: 7px;
     border-color: #D5D7D4;
}

.bg-custom {
     background-color: #218E8E !important;
}

.margenDerechaNivelPerfil {
     margin-right: 8px;
}

.margenIzquerdaNivelPerfil {
     margin-left: 12px;
}

.tomarTodoElEspacioVerticalPerfil {
     height: 91%;
}

.insigniaNoGanada {
     opacity: .5;
}

.divFotoPerfil{
     position: relative;
}

.subirImagen {
     position: absolute;
     z-index: 2;
     color: whitesmoke;
     font-size: 20px;
     /* text-align: right; */
     cursor: pointer;
     border-radius: 10rem;
     background-color: transparent;
     border: transparent;
     left: 65%;
     transform: translateX(-65%);
     padding-top: 4px;
     padding-right: 4px;
 }

 .mensajeEncabezadoPerfil{
      height: 40px;
      
 }

 .subMensajeEncabezadoPerfil {
     font-style: normal;
     font-weight: normal;
     font-size: 18px;
     line-height: 16px;
 }

 .todoElAltoPerfil {
      height: 100%!important;
      margin-top: 20px;
 }

 .paddingAbajoDemoExtras {
      padding-bottom: 10px;
 }

 .requisitosPassFalse2 {
     font-size: 16px;
     font-weight: 400;
     line-height: 20px;
     color: #000000!important;
}

.requisitosPassTrue2 {
     font-size: 16px;
     font-weight: 400;
     line-height: 20px;
     color: #218E8E;
}

form .campo {
     display: flex;
     position: relative;
     margin-bottom: .8rem;
}

form .campo input {
     flex: 1;
     padding: 1.3rem 1rem .5rem .2rem;
     border: none;
     border-bottom: 1px solid #000000;
     color: #888888;
     
}

form .campo input:focus {
/* background-color: #454545; */
outline: none;
}

.campo label {
     position: absolute;
     top: 1.1rem;
     left: 1rem;
     font-size: .9rem;
     transition: font-size .2s ease, top .2s ease;
     color: #000000;
}

form .campo label.valido {
     font-size: .8rem;
     top: .2rem;
}

.campo input:focus + label {
     color: #000000!important;
     font-size: .8rem;
     top: .2rem;
}

form .campo img {
     position: absolute;
     right: 1rem;
     top: 1rem;
}

form .campo img:hover {
     cursor: pointer;
}

.loginBtnB {
     background-color: #218E8E!important;
     border-color: none;
     color: white!important;
     border-radius: .5rem!important;
     height: 3rem;
     font-size: 18px !important;
     font-style: normal;
     font-weight: 700;
     font-size: 18px;
     line-height: 28px;
}

.textoLabelVerdePerfil {
     font-style: normal;
     font-weight: 700;
     font-size: 14px;
     line-height: 16px;
     color: #218E8E;
}

.circuloPositivoCaliFinalResultadosPerfil {
     width: 84px;
     height: 84px;
     border: 8px solid #FFFFFF;
     border-radius: 100%;
}

.textoCirculoPositivoCaliFinalResultadosPerfil {
     font-style: normal;
     font-weight: 700;
     font-size: 30px;
     line-height: 30px;
     color: #FFFFFF;
}

.circuloNegativoCaliFinalResultadosPerfil {
     width: 84px;
     height: 84px;
     border: 8px solid #DF0024;
     border-radius: 100%;
}

.textoCirculoNegativoCaliFinalResultadosPerfil {
     font-style: normal;
     font-weight: 700;
     font-size: 30px;
     line-height: 30px;
     color: #DF0024;
}

.circuloPendienteCaliFinalResultadosPerfil {
     width: 84px;
     height: 84px;
     border: 8px solid #5F5F5F;
     border-radius: 100%;
}

.textoCirculoPendienteCaliFinalResultadosPerfil {
     font-style: normal;
     font-weight: 700;
     font-size: 14px;
     line-height: 30px;
     color: #5F5F5F;
}

.circuloPositivoEvFinalResultadosPerfil {
     width: 84px;
     height: 84px;
     border: 8px solid #218E8E;
     border-radius: 100%;
}

.textoCirculoPositivoEvFinalResultadosPerfil {
     font-style: normal;
     font-weight: 700;
     font-size: 30px;
     line-height: 30px;
     color: #218E8E;
}

.subTextoPositivoCaliFinalPerfil {
     font-style: normal;
     font-weight: 400;
     font-size: 16px;
     line-height: 26px;
     color: #222323;
}

.subTextoNegativoCaliFinalPerfil {
     font-style: normal;
     font-weight: 400;
     font-size: 16px;
     line-height: 26px;
     color: #222323;
}

/* RESPONSIVO */
.demo-height-default{
     min-height: 885px;
}

/*
************************************************
 INICIO RESPONSIVO 
************************************************
*/

/* 
MEDIA QUERIES
Medida de tabletas y móviles 
Se deja como max 767, ya que boostrap tiene sus medias queries como min 768, lo que quiere decir que cambia a partie de 767
*/
@media (max-width: 767px) {
     .espacioInsigniaPerfil {          
          width: 150px!important;
      }
      .encabezadoPerfil {
          font-size: 22px;
          line-height: 30px;
          padding: 10px;
      }

     .btn-toolbar .iconAccordion{
          transform: rotate(270deg);          
     }
}
@media (min-width: 768px) and (max-width: 991px){
     .btn-toolbar .iconAccordion{
          transform: rotate(270deg);          
     }
}
