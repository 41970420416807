.olineG {
  /* outline: 3px solid yellowgreen;  */
}

.olineR {
  /* outline: 3px solid red;  */
}

.olineB {
  /* outline: 3px solid blue;  */
}

.olineP {
  /* outline: 3px solid rgb(255, 0, 166);  */
}


.btn-verdeReto {
    width: 224px!important;
    background: #218E8E!important;
    color: white!important;
    border-radius: 10px!important;
}

.btn-verdeRetoGuardado {
    margin: 20px!important;
    width: 223.86px!important;
    height: 52px!important;
    background: #B7B7B7!important;
    color: black!important;
    font-weight: 900!important;
    border-radius: 10px!important;
}

.btn-RetoParRegresar {
  margin: 20px!important;
  width: 152px!important;
  height: 52px!important;
  background: #B7B7B7!important;
  color: white!important;
  font-weight: 900!important;
  border-radius: 10px!important;
}

.btn-verdeReto.descargado {
  width: 224px!important;
  background: #B7B7B7!important;
  color: white!important;
  border-radius: 10px!important;
}

.tituloIntruccionesReto {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #DF0024;
}

.textoIntruccionesReto {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.evaluarParDiv {
    background-color: #E5E6E4;
    border-radius: 10px;
}

.rowContenidoParesDiv {
    /*height: 92px; RESPONSIVO LRG*/ 
    padding-left: 1rem;
    margin-right: .5rem;
}

.tituloRetoPares {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
}

.textoRetoPares {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #DF0024;
}

.tituloRetoExtra {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    color: #DF0024;
}

input[type=range]{
    height: 15px;
    width: 100%;
    display:block;
    margin:5em auto;
    -webkit-appearance: none; 
    border-radius: 5px;
    /* background-image:linear-gradient(to right, #DF0024 calc(var(--value)*10%), #F5F7F4 0); */
    background-image:linear-gradient(90deg, rgba(223,0,36,1) 0%, rgba(246,211,0,1) calc(var(--value)*10%), rgba(217,217,217,1) calc(var(--value)*10%), rgba(217,217,217,1) 100%);
  }

  input[type=range]::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px black, 0px 0px 1px black;
    border: 5px solid #4D4C4C;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    -webkit-appearance: none;
  }
   
  input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px black, 0px 0px 1px black;
    border: 5px solid #4D4C4C;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }

  input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px black, 0px 0px 1px black;
    border: 5px solid #4D4C4C;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    height: 5px;
  }

  .range-wrap {
    padding: 4px 6px 0px 6px;
    position: relative;
    margin: auto;
  }

  .range-value {
    position: absolute;
    top: 42%;
  }

  .range-value span{
    width: 30px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background: #DF0024;
    color: #fff;
    font-size: 12px;
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 6px;
  }

  /* #spanRangeValue {
    left: calc(var(--value)*10%);
  } */
  
  #search-bar .range-value span:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid #DF0024;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    margin-top: -1px;
  }

  .uploadBox {
    border: 3px solid #F5F7F4;
    box-sizing: border-box;
    border-radius: 10px;
  }

  .uploadBox>label.tituloUB {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: #222323;
    text-align: center;
    width: 100%;
  }

  .uploadBox>label.descUB{
    font-family: Basic Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #5F5F5F;
    text-align: center;
    width: 100%;
  }

  .uploadBox>div>div>button.btn-verde {
    margin: 20px!important;
    width: 223.86px!important;
    height: 52px!important;
    background-color: #218E8E!important;
    color: #fff!important;
  }

  .uploadBox>div>div>button.btn-gris {
  margin: 20px!important;
  width: 223.86px!important;
  height: 52px!important;
  color: #fff!important;
  background-color: #B7B7B7!important;
}

  .encabezadoAcordeonResultados {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
  }

  .resultadosAcordion{
    background-color: #E5E6E4;
  }

  .fechaEvaluacionResultados {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #DF0024;
  }

  .preguntaResultados {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }

  .respuestaResultados {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
  }

  .btn-verdeEvaluarPar, .btn-grisEvaluarPar{
    background-color: #218E8E!important;
    border-color: none!important;
    color: white!important;
    font-style: normal!important;
    font-weight: bold!important;
    font-size: 18px!important;
    line-height: 28px!important;
    border-radius: .7rem!important;
    width: 14rem!important;
    height: 3.25rem!important;
}

.btn-grisEvaluarPar {
  background-color: #B7B7B7 !important;
}

.uploadBoxBottom {
  align-self: flex-end;
  margin-bottom: 50px;
}

.tituloSeccion {
    color: #DF0024;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.parrafoFormReto {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #1A1A1A;
}

.tituloResultadosReto {
  color: #DF0024;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}

.rowTopReto {
  /* height: 6rem; RESPONSIVO LRG */
  background-color: #E5E6E4;
  border-radius: .5rem;
  margin-right: .025rem;
}

.btn-verdeTopReto {
  font-style: normal!important;
  font-weight: 700!important;
  font-size: 18px!important;
  line-height: 18px!important;
  background: #218E8E!important;
  border-radius: 10px!important;
  color: #fff!important;
}

.iconoModalCheck {
  font-size: 4rem;
  color: #218E8E;
}

.verde {
  color: #218E8E;
  font-size: 1.8rem;
  line-height: 36px;
  font-style: normal;
  font-weight: normal;
}

.textoModal {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 36px;
  text-align: center;
}

.btn-verdeModalVolver {
  background-color: #B7B7B7!important;
  color: white!important;
  border-radius: 10px!important;
  width: 242px!important;
}

.btn-ModalClose {
  background-color: #B7B7B7!important;
  color: white!important;
  border-radius: 10px!important;
  width: 140px!important;
}

.btn-verdeModalSiguiente {
  background-color: #218E8E!important;
  color: white!important;
  border-radius: 10px!important;
  width: 242px!important;
}

.btn-verdeModalSiguienteBig {
  background-color: #218E8E!important;
  color: white!important;
  border-radius: 10px!important;
  width: 280px!important;
}

.btn-verdeRetoBack {
  background-color: #218E8E!important;
  color: white!important;
  border-radius: 10px!important;
  height: 52px!important;
  font-style: normal!important;
  font-weight: 700!important;
  font-size: 18px!important;
  line-height: 28px!important;
}

.nombreUsuarioTop{
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}

.calificacionUsuarioTop{
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
}

img.retoFotoTop{
  object-fit: cover;
  border-radius: 100%;
}

/*
************************************************
 INICIO RESPONSIVO 
************************************************
*/
h1.tituloActividad {
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 52px;
  color: #222323;
}
/* 
MEDIA QUERIES
Medida de tabletas y móviles 
Se deja como max 767, ya que boostrap tiene sus medias queries como min 768, lo que quiere decir que cambia a partie de 767
*/
@media (max-width: 767px) {
  .slider-air-distance{
    margin-top: -3em;
    margin-bottom: 3em;
  }
}
@media (min-width: 768px) and (max-width: 991px){
  .slider-air-distance{
    margin-top: -3em;
    margin-bottom: 3em;
  }
}