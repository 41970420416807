/* @font-face {
     font-family:"basic-sans";
     src:url("https://use.typekit.net/af/7008d7/00000000000000007735ac10/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("woff2"),url("https://use.typekit.net/af/7008d7/00000000000000007735ac10/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("woff"),url("https://use.typekit.net/af/7008d7/00000000000000007735ac10/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("opentype");
     font-display:auto;font-style:normal;font-weight:100;
} */

html {
height: 100%;
}

body {
    height: 100%;
    min-height: 100%;
    position: relative;
}

.header {
    background-color: #E5E6E4;
    height: 7rem;
    top: 0;
    position: fixed;
    width: 100%;
}

.headerBreadcrum {
     background-color: #E5E6E4;
     height: 2rem;
     margin-top: -2rem;     
     position: fixed;
     width: 100%;
}

.main {
    background-color: #FFFFFF!important;
    height: 100%;
    margin-top: 9rem;
}

.footer {
    background-color: #E5E6E4;
    height: 3rem;
    bottom: 0;
    position: fixed;
    width: 100%;
}

/*RESPONSIVO:*/
@media (max-width: 768px) {
    .footer {
        position: relative;
    }
}
/*-----*/

.delante {
    z-index: 2;
}

.delanteMas {
    z-index: 3;
}

.contenido {
    margin-top: 2.5rem;
    margin-bottom: 5rem;
}

.nomargenes {
    margin: 0px!important;
    margin-left: 0px!important;
    margin-right: 0px!important;
    padding: 0px;
    --bs-gutter-x: 0px!important;
}

.rowajuste {
    position: fixed;
    width: 100%;
    height: 2rem;
    padding-left: 0px;
}

.btn-verde {
    background-color: #218E8E!important;
    border-color: none!important;
    color: white!important;
    font-style: normal!important;
    font-weight: bold!important;
    font-size: 18px!important;
    line-height: 28px!important;
    border-radius: .7rem!important;
    width: 9.56rem;
    min-height: 3.25rem; /* Ajuste 20220418 */
}

.aire {
    margin-top: 2rem;
}

.airex2 {
    margin-top: 4rem;
}

.menosAire {
    margin-top: 1rem;
}

.aireAbajo {
    margin-bottom: 2rem;
}

.rojo {
    color: #DF0024;
    font-style: normal;
    font-weight: bold;
    font-size: 55px;
    line-height: 52px;
}

.rojo.masChico {
    /*RESPONSIVO:*/
    text-align: center;
    /*---*/
     color: #DF0024;
     font-style: normal;
     font-weight: bold;
     font-size: 35px;
     line-height: 32px;
}

.rojo.masChicoX2 {
     color: #DF0024;
     font-style: normal;
     font-weight: bold;
     font-size: 20px;
     line-height: 17px;
     padding-bottom: 10px;
}

.textoBienvenida {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
}

.modal-content>.modal-title>.row>.col>button>i.cerrarModal {
    font-size: 2.5rem;
    margin-right: 1rem;
    margin-top: 1rem;
    color: #888888;
}

.modal-content>.modal-title>.row>.col>button.botonModal {
    background-color: none;
}

label.verde {
    color: #218E8E;
}

input.checkbox {
     background-color: #B7B7B7;
     border-color: none;
}

.imgPanel {
     object-fit: fill;
     height: 314px;
     border-radius: 5px;
 }

 .navbar-light .navbar-nav .nav-link {
      color: #FFFFFF!important;
 }

 .paddingFooterIzquierda {
     /* margin-left: 1rem;  */
     padding-left: 1rem;
 }

 .paddingFooterIzquierda {
     padding-left: 1rem;
 }

 .paddingFooterDerecha {
     padding-right: 1rem;
 }

 .cuadroGrisBienvenida {
      background-color: #e0e0e0;
      padding: 20px;
      border-radius: 5px;
      height: 100%;
 }

/* .carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  outline: black;
  background-size: 100%, 100%;
  border-radius: 50%;
  border: 1px solid black;
  background-image: none;
}  */

.carousel-control-next-icon:after
{
  content: '>';
  font-size: 55px;
  color: #888888;
  position: relative!important;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 55px;
  color: #888888;
}

.hrBienvenida {
     /* position: relative; */
     margin-top: 10px;
     border: none;
     height: 2px!important;
     background: #A8A0A0;
     margin-bottom: 10px;
}

/* 
************************************************
AGREGADOS 
************************************************
*/
div{
     /*border: 0px solid rgba(255, 209, 3, 0.5);*/
     outline: #DF0024;
}
.wrapper-20{
    padding-left: 20px;
    padding-right: 20px;
}
/*
************************************************
 INICIO RESPONSIVO 
************************************************
*/

/* 
MEDIA QUERIES
Medida de tabletas y móviles 
Se deja como max 767, ya que boostrap tiene sus medias queries como min 768, lo que quiere decir que cambia a partie de 767
*/
@media (max-width: 767px){

     .header {
          background-color: #E5E6E4;
          height: 4.5rem;
          top: 0;
          position: fixed;
          width: 100%;
     }
     .rowajuste {
        position: fixed;
        width: 100%;
        height: 40px;
        padding-left: 0px;
     }
     .headerBreadcrum {
          background-color: #5F5F5F; /*En figma esta como #4D4C4C*/
          height: 40px; /*En figma esta como 40px*/
          margin-top: 0rem;
          top:72px;
          position: fixed;
          width: 100%;
     }
     .main {
          /*background-color: #FFFFFF!important;
          height: 100%;*/
          margin-top: 5.5rem;
      }
      .footer {
          /*background-color: #ffffff;*/
          height: 2rem;          
          position: relative;
          width: auto;
          padding: 2px 0px;
          font-size: 10px;
     }
     .xs-aireAbajo {
        margin-bottom: 2rem;
    }
    .imgPanel {
        height: 105px;
    }
    
}
@media (min-width: 768px) and (max-width: 991px){
    .headerBreadcrum {
         background-color: #5F5F5F; /*En figma esta como #4D4C4C*/
    }
    .sm-aireAbajo {
        margin-bottom: 2rem;
    }
    .imgPanel {
        height: 210px;
    }
}