#navbarLift{
     height: 7rem;
     margin-left: 0%;
}

#navbarLift>div>a{
     padding-left: 4rem;
}

#navbarLift>div>span{
     padding-left: 4rem;
     font-weight: bold;
     font-size: 1.2rem;
     max-width: 440px;
}

#navbarLift>div>ul{
     padding-right: 8rem;
     font-weight: bold;
     font-size: 1.2rem;
}

#navbarLift>div>ul>li{
     margin-right: 1rem;
}

#navbarLift>div>ul>li>a>img.fotoPerfil{
     margin-right: 1rem; 
     position: relative;
     top: -.6rem!important;
     object-fit: cover;
}

#navbarLift>div>ul>li.notificaciones{
     margin-right: 3rem;
}

.navbarNexxo{
     background-color: #e9e9e9!important;
}

.sticky-top {
     padding-top: 56px;
 }

 .nopadding{
     padding: 0 1rem 0 3rem!important;
 }

 .textoNavbar{
      color: orangered;
      align-self: center;
 }

 .separador{
      background-color: #29C7CC;
      height: 5rem;
      width: 1.5px;
      /* margin-left: 4rem; */
 }

 .dropusuario{
      margin-top: 1rem;
      width: 200px;
 }

 .logoContainer{
     min-width: 218px;  
     max-width: 218px;  
     text-align: center;
     vertical-align: middle;
 }

 .nombreUsuario {
     margin-top: .5rem;
     font-style: normal;
     font-weight: normal;
     font-size: 18px;
     line-height: 20px;
     color: #5F5F5F
 }

 .nombrePrograma {
     font-style: normal;
     font-weight: normal;
     font-size: 18px;
     line-height: 28px;
     color: #222323;
 }

 .flechaPerfil{
     width: 7px;
     /* margin-right: 5px; */
     padding-top: 10px;
 }
 

 /* 
************************************************
AGREGADOS 
************************************************
*/
.logo{
     width: 120px;
     height: auto;
}


/*
************************************************
 INICIO RESPONSIVO 
************************************************
*/

/* 
MEDIA QUERIES
Medida de tabletas y móviles 
Se deja como max 767, ya que boostrap tiene sus medias queries como min 768, lo que quiere decir que cambia a partie de 767
*/
@media (max-width: 767px){
     #navbarLift {
          height: auto;
          margin-left: 0%;
      }
     .logoContainer{
          min-width: 85px;  
          max-width: 85px;  
          text-align: center;
          vertical-align: middle;
      }
     .logo{
          width: 85px;
          height: auto;
     }
     #navbarLift>div>ul{
          padding-right: 0px;
          font-weight: bold;
          font-size: 1.2rem;
     }
     #navbarLift>div>ul>li.notificaciones{
          margin-right: 0px;
     }
     #navbarLift>div>ul>li{
          margin-left: 0px;
          margin-right: 0px;
     }
     #navbarLift>div>ul>li>a{
          margin-left: 0px;
          margin-right: 0px;
          padding-left: 0rem;
          padding-right: 0.5rem;
          top: -7px;
     }
     
     #navbarLift>div>ul>li>a>img.fotoPerfil{
          margin-right: 0rem; 
          position: relative;
          /*top: -.6rem!important;*/
          top:auto!important;
          object-fit: cover;
          width: 45px;
          height: 45px;
     }    
}

@media (max-width: 991px){
     #navbarLift>div>span {
          padding-left: 4rem;
          font-weight: bold;
          font-size: 1rem;
          max-width: 440px;
          line-height: 1.2rem;
     }
}


@media (max-width: 1199px){
     #navbarLift>div>ul{
          padding-right: 0px;
          font-weight: bold;
          font-size: 1.2rem;
     }
     #navbarLift>div>ul>li.notificaciones {
          margin-right: 0rem; 
      }
}