.modalDetalleInsignia>div>.modal-content{
     background-image: url("../../../assets/img/FondoInsignias.png");
     background-repeat: no-repeat;
     background-size: 100% 195px;
}

.modalInsigniaGanada>div>.modal-content{
     background-image: url("../../../assets/img/FondoInsignias.png");
     background-repeat: no-repeat;
     background-size: 102% 280px;
}

.textoRojoModalInsignia{
     font-style: normal;
     font-weight: bold;
     font-size: 18px;
     line-height: 18px;
     color: #DF0024;
}

.textNombreInsigniaModal {
     font-style: normal;
     font-weight: bold;
     font-size: 26px;
     line-height: 26px;
     color: #FFFFFF;
}

.textoDescripcionInsigniaModal {
     font-style: normal;
     font-weight: normal;
     font-size: 20px;
     line-height: 30px;
     color: #1A1A1A;
     text-align: center;
}

.divGrisInsignia{
     background-color: #4D4C4C;
     border-radius: 10px;
     /* height: 50px; */
}

.tituloInsigniaGanada {
     font-style: normal;
     font-weight: 700;
     font-size: 50px;
     line-height: 16px;
     color: #F6D300;
}

.subtituloInsigniaGanada {
     font-style: normal;
     font-weight: 700;
     font-size: 24px;
     line-height: 30px;
     color: #FFFFFF;
}

.modalInsigniaGanada>div>.modal-content>.modal-body>.row>.col>button.btnVerdeInsigniaGanada {
     background: #218E8E;
     border-radius: 10px;
     font-style: normal;
     font-weight: 700;
     font-size: 18px;
     line-height: 28px;
     color: #EEEDED;
}

.MasAire {
     margin-top: 45px;
}